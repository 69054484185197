import React, { useEffect, useState } from "react";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton as MuiIconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MapLoader from "../All-Loader/mapLoader"; // Import your MapLoader component

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isRtl }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: isRtl ? 15 : 12,
  },
}));

const Feedback = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false); // State to control loader visibility
  const [openVideoModal, setOpenVideoModal] = useState(false); // State for controlling the modal
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Show video modal if user is new
  useEffect(() => {
    const isNewUser = localStorage.getItem("isNewUser");

    if (isNewUser === "true") {
      // If the user is new, show the modal and play the video
      setOpenVideoModal(true);
      // Remove the flag after showing the video so it doesn't show again
      localStorage.removeItem("isNewUser");
    }
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFeedbackClick = () => {
    const phoneNumber = "+923001750077";
    const message = encodeURIComponent(
      "Welcome in Kisan 360, please share your feedback with us."
    );
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank");
    handleMenuClose();
  };

  const handleContactUs = () => {
    navigate("/contact-us");
    handleMenuClose();
  };

  const handleAboutUs = () => {
    setLoading(true); // Show loader
    setTimeout(() => {
      setLoading(false); // Hide loader before navigating
      navigate("/AboutUs");
    }, 2500); // Adjust the delay as needed

    handleMenuClose();
  };

  const handleDemoVideoClick = () => {
    setOpenVideoModal(true); // Open modal when clicked
    handleMenuClose();
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const isMenuOpen = Boolean(anchorEl);
  const menuId = "feedback-dropdown-menu";

  return (
    <>
      {loading && <MapLoader />} {/* Show loader if loading is true */}
      <CustomTooltip
        title={t("Send Feedback")}
        placement={i18n.dir() === "rtl" ? "left" : "right"}
      >
        <IconButton onClick={handleMenuOpen} sx={{ color: "white" }}>
          <FeedbackIcon fontSize="medium" />
        </IconButton>
      </CustomTooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": { color: "white", backgroundColor: "black" },
        }}
      >
        <MenuItem onClick={handleDemoVideoClick}>{t("Demo Video")}</MenuItem>
        <MenuItem onClick={handleFeedbackClick}>{t("Feedback")}</MenuItem>
        <MenuItem onClick={handleAboutUs}>{t("About Us")}</MenuItem>
      </Menu>
    {/* Modal for showing the demo video */}
    <Dialog open={openVideoModal} onClose={handleCloseVideoModal} fullWidth maxWidth="md">
        <DialogTitle sx={{display:"flex", alignItems:"center"}}>
          {t("Demo Video")}
          <MuiIconButton
            aria-label="close"
            onClick={handleCloseVideoModal}
            sx={{
              position: "absolute",
              right: 8,
              // top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </MuiIconButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            "&::-webkit-scrollbar": {
              width: "12px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#2e2e2e", // Dark color for the track
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#555", // Darker scrollbar thumb color
              borderRadius: "6px",
              border: "2px solid #2e2e2e", // Border around the thumb to match the background
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#888", // Slightly lighter on hover
            },
          }}
        >
          <video
            width="100%"
            controls
            autoPlay // Automatically play the video when modal opens
            src="https://storage.googleapis.com/kisan360-video-public/kisan360-public.mp4"
          >
            Your browser does not support the video tag.
          </video>    
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Feedback;
