import React, { useState } from "react";
import {
  ThemeProvider,
  createTheme,
  styled,
  useTheme,
} from "@mui/material/styles";
import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import MapLoader from "../All-Loader/mapLoader";

const TermsContent = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(6),
  maxWidth: "900px",
  margin: "auto",
  backgroundColor: "#2e3b4a",
  borderRadius: "10px",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
  textAlign: "left",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(4),
    margin: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3),
    margin: "15px",
  },
}));

const TermsAndConditionsPage = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  if (loading) return <MapLoader />;

  return (
    <div
      style={{
        backgroundColor: "#212930",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
            background: {
              default: "#212930",
            },
            text: {
              primary: "#9fb4c7",
            },
          },
          typography: {
            fontFamily: "Roboto, Arial, sans-serif",
          },
        })}
      >
        <TermsContent>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: isLargeScreen ? "2rem" : "1.5rem",
              textAlign: "center",
            }}
          >
            Terms and Conditions
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#9fb4c7",
              marginBottom: "20px",
              fontSize: isLargeScreen ? "1rem" : "0.9rem",
              textAlign: "center",
            }}
          >
            Last updated: 15-11-2024
          </Typography>
          <Divider sx={{ backgroundColor: "#555", margin: "20px 0" }} />
          {/* Sections */}
          {[
            {
              title: "1. Acceptance of Terms",
              content: `
              By accessing or using Kisan360, you agree to be bound by these Terms and Conditions.
              `,
            },
            {
              title: "2. Use of Kisan360",
              content: `
              Kisan360 allows you to monitor your fields, access crop health and weather data, and utilize satellite imagery for agricultural insights. You are responsible for ensuring that any data you provide is accurate.
              `,
            },
            {
              title: "3. Restrictions",
              content: `
              You agree not to:
              - Use Kisan360 in violation of any applicable laws or regulations.
              - Attempt to reverse-engineer, reproduce, or modify Kisan360’s software or services.
              `,
            },
            {
              title: "4. Data and Analysis Limitations",
              content: `
              Satellite imagery and weather data are provided for informational purposes. We do not guarantee the accuracy of this data and are not liable for decisions made based on these insights.
              `,
            },
            {
              title: "5. User Responsibilities",
              content: `
              You are responsible for securing your account and ensuring the accuracy of any data you input. Kisan360 is not liable for any errors resulting from user-provided data.
              `,
            },
            {
              title: "6. Modifications to Terms",
              content: `
              We may modify these Terms from time to time, with updates posted here. Your continued use of Kisan360 constitutes acceptance of the revised terms.
              `,
            },
            {
              title: "7. Disclaimer of Warranties",
              content: `
              Kisan360 is provided on an “as is” and “as available” basis. We make no warranties or guarantees regarding the accuracy, completeness, or reliability of the services.
              `,
            },
            {
              title: "8. Limitation of Liability",
              content: `
              To the fullest extent permitted by law, Kisan360 is not liable for any damages arising from the use or inability to use our services.
              `,
            },
            {
              title: "9. Governing Law",
              content: `
              These Terms and your use of Kisan360 are governed by the laws of Pakistan.
              `,
            },
            {
              title: "Contact Us",
              content: `
              For questions regarding these Terms and Conditions, please contact us at +92 300 1750077.
              `,
            },
          ].map((section, index) => (
            <React.Fragment key={index}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                {section.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#9fb4c7",
                  marginBottom: "20px",
                  lineHeight: 1.6,
                  fontSize: isLargeScreen ? "1rem" : "0.9rem",
                }}
              >
                {section.content}
              </Typography>
              {index < 9 && (
                <Divider sx={{ backgroundColor: "#555", margin: "20px 0" }} />
              )}
            </React.Fragment>
          ))}
        </TermsContent>
      </ThemeProvider>
    </div>
  );
};

export default TermsAndConditionsPage;
