// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import CopernicusHarmonized from "./pages/Sentinel-2/Copernicus-Harmonized";
import RightSideDrawer from "./pages/Side-Drawer/right-drawer";
import CustomOverlay from "./pages/Sentinel-2/custimOverlay";
import Sentinel2Harmonized from "./pages/Sentinel-2/sentinel2Harmonized";
import Sentinel2HarmonizedStatic from "./pages/Sentinel-2/sentinel2HarmonizedStatic";
import MapLoader from "./pages/All-Loader/mapLoader";
import { SnackbarProvider } from "notistack";
import TestPdf from "./pages/Create-PDF/testPdf";
import OpenWeatherTestApi from "./pages/weather-forecast/openWeatherTestApi";
import DevisVantageWeather from "./pages/weather-forecast/Live-Weather-Station/davisVantageFaisalabad";
import BurewalaWeather from "./pages/weather-forecast/Live-Weather-Station/burewalaWeather";
import OkaraWeather from "./pages/weather-forecast/Live-Weather-Station/okaraWeather";
import TobaCampusWeather from "./pages/weather-forecast/Live-Weather-Station/tobaCampus";
import CASFaisalabad from "./pages/weather-forecast/Live-Weather-Station/casFaisalabad";
import WeatherForecast from "./pages/weather-forecast/custom-weather-widget-dashboard";
import ReportPage from "./pages/Report-Page/reportPage";
import SignUp from "./pages/Sign-Up/signUp";
import Login from "./pages/Login/login";
import PrivateRoute from "./pages/Private-Routes/privateRoute";
import "./utils/i18next";
import GoogleLoginSuccess from "./pages/Login/LoginSuccess";
import DrawerTest from "./pages/MaterialUi-Components/drawertest";
import PolygoneDrawer from "./pages/MaterialUi-Components/polygoneDrawer";
import AboutUs from "./pages/About-Us/aboutUs";
import Swal from "sweetalert2";
import "./App.css";
import TestAudioReport from "./pages/Report-Page/testAudioReport";
import NotFound from "./pages/Not-Found/notFound"; // Import the NotFound component
import PrivacyPolicy from "./pages/Privacy-Policy/privacyPolicy";
import TermsAndCondition from "./pages/TermsAndConditions/TermsAndConditions"
function SessionHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = () => {
      const expirationTime = localStorage.getItem("expirationTime");
      const currentTime = Date.now();

      if (expirationTime && currentTime > expirationTime) {
        console.log("Session expired, clearing storage and redirecting...");

        // Clear all localStorage
        localStorage.clear();

        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Session expired, please log in again.",
          showConfirmButton: true,
          customClass: {
            popup: "swal-dark", // Custom class for dark theme
            title: "swal-dark-title",
            icon: "swal-dark-icon",
          },
          background: "#333", // Dark background color
          color: "#fff", // White text color
        });
        navigate("/login");
      } else {
        console.log("Session still valid.");
      }
    };

    // Run the check immediately
    checkSession();

    // Set an interval to check every minute
    const interval = setInterval(checkSession, 60000); // 60000 ms = 1 minute

    // Clean up on component unmount
    return () => clearInterval(interval);
  }, [navigate]);

  return null;
}

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <BrowserRouter>
      <SessionHandler />
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: "top", // Position vertically at the top
          horizontal: "center", // Center horizontally
        }}
      >
        {loading && <MapLoader />}
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
          <Route path="/login-success" element={<GoogleLoginSuccess />} />
          <Route
            path="*"
            element={<PrivateRoute>{<NotFound />} </PrivateRoute>}
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Sentinel2Harmonized />
              </PrivateRoute>
            }
          />
          <Route
            path="/CopernicusHarmonized"
            element={
              <PrivateRoute>
                <CopernicusHarmonized />
              </PrivateRoute>
            }
          />
          <Route
            path="/Sentinel2HarmonizedStatic"
            element={
              <PrivateRoute>
                <Sentinel2HarmonizedStatic />
              </PrivateRoute>
            }
          />
          <Route
            path="/OpenWeatherTestApi"
            element={
              <PrivateRoute>
                <OpenWeatherTestApi />
              </PrivateRoute>
            }
          />
          <Route
            path="/TestPdf"
            element={
              <PrivateRoute>
                <TestPdf />
              </PrivateRoute>
            }
          />
          <Route
            path="/CustomOverlay"
            element={
              <PrivateRoute>
                <CustomOverlay />
              </PrivateRoute>
            }
          />
          <Route
            path="/RightSideDrawer"
            element={
              <PrivateRoute>
                <RightSideDrawer />
              </PrivateRoute>
            }
          />
          <Route
            path="/ReportPage"
            element={
              <PrivateRoute>
                <ReportPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/DrawerTest"
            element={
              <PrivateRoute>
                <DrawerTest />
              </PrivateRoute>
            }
          />
          <Route
            path="/PolygoneDrawer"
            element={
              <PrivateRoute>
                <PolygoneDrawer />
              </PrivateRoute>
            }
          />
          <Route
            path="/DevisVantageWeather"
            element={
              <PrivateRoute>
                <DevisVantageWeather />
              </PrivateRoute>
            }
          />
          <Route
            path="/BurewalaWeather"
            element={
              <PrivateRoute>
                <BurewalaWeather />
              </PrivateRoute>
            }
          />
          <Route
            path="/OkaraWeather"
            element={
              <PrivateRoute>
                <OkaraWeather />
              </PrivateRoute>
            }
          />
          <Route
            path="/TobaCampusWeather"
            element={
              <PrivateRoute>
                <TobaCampusWeather />
              </PrivateRoute>
            }
          />
          <Route
            path="/CASFaisalabad"
            element={
              <PrivateRoute>
                <CASFaisalabad />
              </PrivateRoute>
            }
          />
          <Route
            path="/WeatherForecast"
            element={
              <PrivateRoute>
                <WeatherForecast />
              </PrivateRoute>
            }
          />
          <Route
            path="/AboutUs"
            element={
              <PrivateRoute>
                <AboutUs />
              </PrivateRoute>
            }
          />
          <Route
            path="/TestAudioReport"
            element={
              <PrivateRoute>
                <TestAudioReport />
              </PrivateRoute>
            }
          />
        </Routes>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
