import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme, styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MapLoader from "../All-Loader/mapLoader";

const PolicyContent = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(6),
  maxWidth: "900px",
  margin: "auto",
  backgroundColor: "#2e3b4a",
  borderRadius: "10px",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
  textAlign: "left",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(4),
    margin: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3),
    margin: "15px",
  },
}));

const PrivacyPolicyPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const handleGoBack = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/"); // Navigate to the home page
    }, 2500); // Set a delay before navigation
  };

  if (loading) return <MapLoader />;

  return (
    <div style={{ backgroundColor: "#212930", minHeight: "100vh", display: "flex", alignItems: "center" }}>
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
            background: {
              default: "#212930",
            },
            text: {
              primary: "#9fb4c7",
            },
          },
          typography: {
            fontFamily: "Roboto, Arial, sans-serif",
          },
        })}
      >
        <PolicyContent>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: isLargeScreen ? "2rem" : "1.5rem",
              textAlign: "center",
            }}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#9fb4c7",
              marginBottom: "20px",
              fontSize: isLargeScreen ? "1rem" : "0.9rem",
              textAlign: "center",
            }}
          >
            Last updated: 15-11-2024
          </Typography>
          <Divider sx={{ backgroundColor: "#555", margin: "20px 0" }} />
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "#ffffff", fontWeight: "bold", fontSize: "1.2rem" }}
          >
            Introduction
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#9fb4c7",
              marginBottom: "20px",
              lineHeight: 1.6,
              fontSize: isLargeScreen ? "1rem" : "0.9rem",
            }}
          >
            Welcome to Kisan360, a platform created to assist farmers in
            monitoring their fields, analyzing crop health, and accessing
            weather data. We value your privacy and are committed to
            protecting your personal information. This Privacy Policy
            explains how we collect, use, and share information about you when
            you use our services.
          </Typography>

          {/* Sections */}
          {[
            {
              title: "1. Information We Collect",
              content: `
              - Field Data: When you use Kisan360 to mark a field, we collect and store details like field name, crop type, sowing and harvesting dates, and location.
              - Satellite Imagery Data: Kisan360 utilizes satellite images from Earth Engine to provide crop health, moisture content, and nitrogen content analysis.
              - Weather Data: We provide field-specific weather forecasts and access live weather station data for specific locations in Pakistan (Toba, Burewala, Faisalabad, Okara).
              - Technical Information: Information such as your device type, IP address, and usage statistics may be collected to improve our services.
              `,
            },
            {
              title: "2. How We Use Your Information",
              content: `
              - Provide and improve our services.
              - Generate satellite imagery insights specific to your fields.
              - Offer location-based weather forecasts.
              - Enhance the user experience and improve app functionality.
              `,
            },
            {
              title: "3. Sharing Your Information",
              content: `
              - Service Providers: Third-party vendors who assist in our service delivery (e.g., Earth Engine for satellite data).
              - Legal Requirements: Governmental or legal authorities if required by law.
              `,
            },
            {
              title: "4. Data Retention",
              content: `
              We retain your data as long as needed to provide our services and comply with legal obligations. You can request deletion of your data by contacting us at [Contact Information].
              `,
            },
            {
              title: "5. Data Security",
              content: `
              We implement technical and organizational measures to protect your data from unauthorized access, loss, and misuse.
              `,
            },
            {
              title: "6. Children’s Privacy",
              content: `
              Kisan360 is not intended for children under 13, and we do not knowingly collect data from children.
              `,
            },
            {
              title: "7. Updates to this Privacy Policy",
              content: `
              We may update our Privacy Policy periodically. Any changes will be posted here with an updated effective date.
              `,
            },
            {
              title: "Contact Us",
              content: `
              For questions regarding this Privacy Policy, please contact us at +92 300 1750077.
              `,
            },
          ].map((section, index) => (
            <React.Fragment key={index}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                {section.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#9fb4c7",
                  marginBottom: "20px",
                  lineHeight: 1.6,
                  fontSize: isLargeScreen ? "1rem" : "0.9rem",
                }}
              >
                {section.content}
              </Typography>
              {index < 7 && <Divider sx={{ backgroundColor: "#555", margin: "20px 0" }} />}
            </React.Fragment>
          ))}
        </PolicyContent>
      </ThemeProvider>
    </div>
  );
};

export default PrivacyPolicyPage;
